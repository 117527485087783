.header {
    height: 15vh;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-secondary-dark, 0.3),
        rgba($color-black, 0.3)),
    url(../../img/nightPart.jpg);
    background-size: cover;
    background-position: top;
    position: relative;
    
    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        -webkit-clip-path:polygon(20% 0%, 80% 0%, 100% 0, 100% 100%, 66% 90%, 33% 90%, 0 100%, 0 0);
        clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 100%, 66% 90%, 33% 90%, 0 100%, 0 0);
        height: 40vh;
    }

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
            only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-light, 0.3),
            rgba($color-primary-dark, 0.3)),
        url(../../img/nightPart.jpg);
    }

    @include respond(phone) {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    }
    &__text-box {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}