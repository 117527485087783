.spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    height: 18px;
    width: 18px;
    position: absolute;
    top: 6%;
    left: 70%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
    @include respond(tab-port) {
        top: 6%;
        left: 28%;
    }
    @media only screen and (max-width: 766px) { 
        left:34%
    }
    @include respond(phone) {
        top: 6%;
        left: 64%;
    }
    }