@import url("https://fonts.googleapis.com/css?family=Montserrat");
@charset "UTF-8";

.resume {
    font-family: "Montserrat", sans-serif;
    width: 96rem;
    background: rgba($darkest-color, .5);
    color: $text-background;
    margin: 2rem auto;
    // box-shadow: 1rem 1rem $shadow;
    position: relative;
    display: flex;
    @media only screen and (max-width: 75em){ // width < 900?
      font-size: 50%; //1 rem = 8px, 8/16 = 50%
      width: 100%;
      padding: 4rem 10rem 4rem 10rem;
      margin: 0;
  }
    @media only screen and (max-width: 56.25em) {
      font-size: 75%; //1rem = 12, 12/16
      padding: 0;
      flex-direction: column;
  }
  a {
    text-decoration: none;
    color: $text-background;
    display: block;
    transition-duration: 0.3s;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  h2 {
  }
  
  h3 {
    color: $icons;
    margin: 1rem 0;
    text-transform: lowercase;
    font-size: 2.4rem;
  }
  .base,
  .func {
     box-sizing: border-box;
     float: left;
     font-size: 1.7rem;
     @media only screen and (max-width: 56.25em) {
       font-size: 1.4rem;
   }
   }
  .base > div,
  .func > div {
     padding-bottom: 2rem;
   }
  .base > div:last-of-type,
  .func > div:last-of-type {
     padding-bottom: 0;
   }
   .Exp-Edu{
     display: flex;
     flex-direction: row;
     @include respond(tab-port) {
      flex-direction: column;
     }
   }
}
