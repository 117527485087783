#gradient {
    height: 70vh;
    border: solid 3px white;
	font: 'Raleway', sans-serif;
    color: rgba(22, 21, 21, 0.5);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5em;
    background: linear-gradient(to right,#004080 , #008080); /* Standard syntax */
    // @media only screen and (max-width: 56.25em) {
    //     height: ;;
    //     }
    h1 {
        margin-top: 25vh;
        font: 600 3.5em 'Raleway', sans-serif;
        color: rgba(0,0,0,.5);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .3em;
        width: 100%;
    }
    
    h3 {
        font: 900 1em 'Raleway', sans-serif;
        color: rgba(0,0,0,.5);
        text-align: center;
        text-transform: none;
        letter-spacing: 0.01em;
    
    }
}


