.resume .func {
  width: 100%;
  padding: 3rem;
  @media only screen and (max-width: 37.5em) {
    padding: 0;
  }
}
.resume .func:hover > div {
  transition-duration: 0.5s;
}
.resume .func:hover > div:hover h3 i {
  transform: scale(1.25);
}
.resume .func:hover > div:not(:hover) {
  opacity: 0.5;
}
.resume .func h3 {
  transition-duration: 0.3s;
  margin-top: 0;
}
.resume .func h3 i {
  color: $darker-color;
  background: $icons;
  width: 4.2rem;
  height: 4.2rem;
  font-size: 2rem;
  line-height: 4.2rem;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: 0.8rem;
  transition-duration: 0.3s;
}
