//Square animation
@keyframes move-left-right {
    0% {
      transform: translateX(-10px); }
    50% {
      transform: translateX(10px); }
    100% {
      transform: translateX(-10px); } }

    @keyframes move-top-bottom {
    0% {
        transform: translateY(-8px); }
    50% {
        transform: translateY(8px); }
    100% {
        transform: translateY(-8px); } }
//others

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
//spiner
@keyframes spinner {
    0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
    }
