#tsparticles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/* Tabs */
.tabs {
  // display: flex;
  // border-bottom: 2px solid #ccc;
  margin-bottom: 1em;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  color: rgba(247, 247, 247, 0.85);
}

.tab--active {
  border-bottom: 2px solid #19b1ac;
  color: #19b1ac;
  font-weight: bold;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
}
/* Loading.css */

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.loading-container {
  p {
    margin-top: 20px;
    font-size: 1.5em;
    color: #333;
  }
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #19b1ac;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
