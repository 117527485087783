@import "../utils/mixins";
@import "../utils/variables";

.footer {
    border-top: 2px solid $color-primary;
    background: radial-gradient(ellipse at bottom,$color-secondary-dark 30%,$color-black 80%);
    padding: 6rem 0;
    font-size: 1.4rem;
    color: $color-grey-light-1;
    z-index: 20;
    position: relative;

    @include respond(tab-port) {
        padding: 8rem 0;
    }

    //social links
    
    .back-to-top  {
        display: flex;
        justify-content: center;
        font-size: 3rem;
        color: $color-white;
        margin: 1rem 0 $default-font-size;
        transition: all 0.2s ease-in;
        &:hover {
          transform: translateY(-4px);
          color:$color-primary;
        }
      }
       .social-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        a {
          color: $color-white;
          font-size: 4rem;
          width: 5rem;
          height: 5rem;
          margin: $default-font-size $default-font-size;
          transition: all 0.2s ease-in;
      
          &:hover {
            transform: translateY(-4px);
            color: $color-primary;
          }
        }
      }

      //End-Social-links

    &__navigation {
        border-top: 1px solid $color-grey-dark;
        padding-top: 2rem;
        display: inline-block;

        @include respond(tab-port) {
            width: 100%;
            text-align: center;
        }
    }

    &__list {
        list-style: none;
    }

    &__item {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }

    &__link {
        &:link,
        &:visited {
            color: $color-grey-light-1;
            background-color: $color-grey-dark-3;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-block;
            transition: all .2s;
        }

        &:hover,
        &:active {
            color: $color-primary;
            box-shadow: 0 1rem 2rem rgba($color-black, .4);
            transform: rotate(5deg) scale(1.3);
        }
    }

    &__copyright {
        border-top: 1px solid $color-grey-dark;
        padding-top: 2rem;
        width: 80%;
        float: right;

        @include respond(tab-port) {
            width: 100%;
            float: none;
        }
    }
}