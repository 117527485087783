.resume .func .work small,
.resume .func .edu small {
  display: block;
  opacity: 0.7;
}
.resume .func .work ul li,
.resume .func .edu ul li {
  position: relative;
  margin-left: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 1.5rem;
}
.resume .func .work ul li:hover::before,
.resume .func .edu ul li:hover::before {
  animation: circle 1.2s infinite;
}
.resume .func .work ul li:hover span,
.resume .func .edu ul li:hover span {
  color: $text-hover;
}
@keyframes circle {
  from {
    box-shadow: 0 0 0 0rem $text-hover;
  }
  to {
    box-shadow: 0 0 0 .6rem rgba(255, 255, 255, 0);
  }
}
.resume .func .work ul li:first-of-type::before,
.resume .func .edu ul li:first-of-type::before {
  width: 1rem;
  height: 1rem;
  left: .1rem;
}
.resume .func .work ul li:last-of-type,
.resume .func .edu ul li:last-of-type {
  padding-bottom: .3rem;
}
.resume .func .work ul li:last-of-type::after,
.resume .func .edu ul li:last-of-type::after {
  border-radius: .15rem;
}
.resume .func .work ul li::before,
.resume .func .work ul li::after,
.resume .func .edu ul li::before,
.resume .func .edu ul li::after {
  content: "";
  display: block;
  position: absolute;
}
.resume .func .work ul li::before,
.resume .func .edu ul li::before {
  width: .7rem;
  height: .7rem;
  border: .3rem solid $text-background;
  background: $icons;
  border-radius: 50%;
  left: .3rem;
  z-index: 1;
}
.resume .func .work ul li::after,
.resume .func .edu ul li::after {
  width: .3rem;
  height: 100%;
  background: $text-background;
  left: .5rem;
  top: 0;
}
.resume .func .work ul li span,
.resume .func .edu ul li span {
  transition-duration: 0.3s;
}
.resume .func .work {
  width: 48%;
  background: $darker-color;
  padding: 1.5rem;
  margin: 0 1rem 2rem 0;
  @media only screen and (max-width: 56.25em) {
    width: 100%;
}
}
.resume .func .edu {
  width: 48%;
  background: $darker-color;
  margin: 0 0 1rem 0;
  padding: 1.5rem;
  @media only screen and (max-width: 56.25em) {
    width: 100%;
}
}