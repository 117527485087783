.popup {
  display: block;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($color-black, 0.9);
  z-index: 9999;
  opacity: 1;
  transition: all 0.3s;

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($color-black, 0.3);
  }

  &__content {
    @include absCenter;

    width: 75%;
    background-color: rgba($color-secondary-dark, 0.9);
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    border-radius: 3px;
    display: table;
    // overflow: hidden;
    opacity: 1;
    // transform: translate(-50%, -50%) scale(.25);
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.5s 0.2s;
    @media only screen and (max-width: 56.25em) {
      width: 100%;
    }
  }

  &__left {
    width: 33.333333%;
    display: table-cell;
    @media only screen and (max-width: 56.25em) {
      display: none;
    }
  }

  &__right {
    height: 80vh;
    width: 66.6666667%;
    display: table-cell;
    vertical-align: middle;
    padding: 3rem 5rem;

    @media only screen and (max-width: 56.25em) {
      width: 100%;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__text {
    font-size: 1.5rem;
    margin-bottom: 4rem;
    word-spacing: 0.2rem;

    -moz-column-count: 2;
    -moz-column-gap: 4rem; //1em = 14px;
    -moz-column-rule: 1px solid $color-grey-light-2;

    column-count: 2;
    column-gap: 4rem; //1em = 14px;
    column-rule: 1px solid $color-grey-light-2;

    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    @media only screen and (max-width: 56.25em) {
      column-count: 1;
    }
    a {
      color: $color-white;
    }
  }

  //Open states
  // &:target {
  //     opacity: 1;
  //     visibility: visible;
  // }

  // &:target &__content {
  //     opacity: 1;
  //     transform: translate(-50%, -50%) scale(1);
  // }

  &__close {
    color: $color-grey-dark;
    cursor: pointer;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    text-decoration: none;
    display: inline-block;
    transition: all 0.2s;
    line-height: 1;

    &:hover {
      color: $color-primary;
    }
  }
}
