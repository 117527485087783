body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;

    backface-visibility: hidden;
    margin-bottom: 6rem;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;
    
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        
        @include respond(phone) {
            letter-spacing: 1rem;
            font-family: 5rem;
        }
        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }
    
    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.75rem;
        animation: moveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: .5rem;
        }
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to bottom, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;
    z-index: 2;

    @include respond(tab-port) {
        font-size: 3rem;
    }

    @include respond(phone) {
        font-size: 2.5rem;
    }

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
    word-spacing: .2rem;
}


.paragraph {
    font-size: 1.5rem;
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}
//links
.links{
    text-decoration:none;
    color: $color-primary-light;
}

.frLinks{
    text-decoration: none;
    color:$color-blue-3;
}
//Firdaws
.frMiniTitle{
    font-size: 2rem;
    // line-height: 1.6rem;
    letter-spacing: 0.5rem;
    text-align: center;
    text-transform: uppercase;
}
.frTitle{
    font-size: 6.5rem;
    line-height: 8.6rem;
    letter-spacing: 0.3rem;
    margin-bottom: 5rem;
    // max-width: 5rem;
}
// horizontal title
.hr-title{
    font-size: 1.5rem;
    margin: 0 10rem 0 10rem;
    border-bottom: 0.41px solid $color-primary-light;
    padding: 0 4rem 0 4rem;
    &--firdaws{
        border-bottom: 0.41px solid $color-blue-2;
    }
}
//colors:
.yellow{
    color:rgba($javascript,0.8);
}
.css{
    color: rgba($css,0.9);
}