/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

.typingSpeed {
	font-family: 'Source Sans Pro', 'Helvetica', Arial, sans-serif;
	font-size: 18px;
    line-height: 1.5;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
	clear: both;
    }

    p {
	margin-bottom: 1.5em;
    }

    b,
    strong {
        font-weight: bold;
    }
    
    dfn,
    cite,
    em,
    i {
        font-style: italic;
    }
    
    blockquote {
        margin: 0 1.5em;
    }
    
    address {
        margin: 0 0 1.5em;
    }
    
    pre {
        background: #eee;
        font-family: "Courier 10 Pitch", Courier, monospace;
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.6;
        margin-bottom: 1.6em;
        max-width: 100%;
        overflow: auto;
        padding: 1.6em;
    }
    
    code,
    kbd,
    tt,
    var {
        font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    }
    
    abbr,
    acronym {
        border-bottom: 1px dotted #666;
        cursor: help;
    }
    
    mark,
    ins {
        background: #fff9c0;
        text-decoration: none;
    }
    
    sup,
    sub {
        font-size: 75%;
        height: 0;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    
    sup {
        bottom: 1ex;
    }
    
    sub {
        top: .5ex;
    }
    
    small {
        font-size: 75%;
    }
    
    big {
        font-size: 125%;
    }
    
    /*--------------------------------------------------------------
    Layout
    --------------------------------------------------------------*/
    // body {
    //     margin: 0;
    //     padding: 0;
    // }
    
    .masthead {
        padding: 1em 2em;
        background-color: #0D1B2E;
        color: white;
    }
    
    .masthead h1 {
        text-align: center;
    }
    
    .intro {
        padding: 2em 2em;
        color: #ffffff;
        background: #429890;
    }
    
    
    .intro p,
    .test-area {
        margin: 0 auto;
        max-width: 550px;
    }
    
    .test-area {
        margin-bottom: 4em;
        padding: 0 2em;
    }
    
    .test-wrapper {
        border: 10px solid grey;
        border-radius: 10px;
    }
    
    #origin-text {
        margin: 1em 0;
        padding: 1em 1em 0;
        background-color: #5e5e7c;
    }
    
    #origin-text p {
        margin: 0;
        padding-bottom: 1em;
    }
    
    .test-wrapper {
        height: 200%;
        display: flex;
    }
    
    .test-wrapper textarea {
        flex: 1;
    }
    
    .meta {
        margin-top: 1em;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .timer {
        font-size: 3em;
        font-weight: bold;
    }
    
    #reset {
        padding: .5em 1em;
        font-size: 1.2em;
        font-weight: bold;
        color: #E95D0F;
        background: white ;
        border: 10px solid #E95D0F;
    }
    
    #reset:hover {
        color: white;
        background-color: #E95D0F;
    }
    
}



