*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    scroll-behavior: smooth;
    
}
html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) { // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) { // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

.portfolio{
  background-image: linear-gradient(to right bottom, $color-secondary-dark, $color-black);
  color: rgba($color-grey-light-1, .85);
  box-sizing: border-box;
  /*-----------------------
     text selection
-------------------------*/
  ::selection {
    background-color: $color-primary;
    color: $color-white;
}
/*-------------------
      Scrollbar 
----------------------*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary-light; 
}
 /* arrows pointing upwards and downwards */
::-webkit-scrollbar-button{
  background: $color-secondary-light;
}
}



  