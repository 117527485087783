// Portfolio COLORS
$background: #a3a09e;
$color-primary: #077370;
$color-primary-light: #19b1ac;
$color-primary-dark: #044442;

$color-secondary-light: #5e5e7c;
$color-secondary-dark: #232333;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$javascript: #f8e01e;
$css:rgb(44, 113, 204);


// FONT
$default-font-size: 1.6rem;

// FONT VARIABLES
$font-portfolio:"Lato", sans-serif;
$font-portfolio-about:"Montserrat", sans-serif;

//from firdaws project 1
$font-primary: 'Nunito', sans-serif;
$font-display: 'Josefin Sans', sans-serif;
// end

$font-firdaws:'Playfair Display', serif;

$font-blog-primary:'Martel', serif;
$font-blog-title:'Cinzel', serif;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

//Firdaws
//FIRDAWS COLOR:
$color-brown-1: #54483A;
$color-brown-2: #6D5D4B;

$color-blue-1:#101d2c;
$color-blue-2: #115f77;
$color-blue-3: #278fc4;
$color-blue-4: #00b8ac;
$color-blue-5: #6fc4d8;


$color-green-1:#077370;

$color-other-1:#5e5e7c;
$color-other-2:#232333;

$color-logo-1:#5968b1;
$color-logo-2:#97e6e4;
$color-logo-3:#baefed;
$color-logo-4:#e6f9f9;

// Resume Variables
$darkest-color: #232333;
$darker-color: #323247;
$dark-color: #373753;
$medium-color: #4c4c77;
$light-color: #6b6ba3;
$lighter-color: #7171a8;
$lightest-color: #9292e2;

$text-background: #ffffff;
$shadow: #0e1442;
$icons: #077370;
$text-hover: #19b1ac;